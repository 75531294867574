import React, { useEffect, useState } from "react";
import styles from "./formView.module.scss";
import { useDispatch, useSelector } from "react-redux";
import FormViewFooter from "./formViewFooter/formViewFooter";
import QuestionPage from "./questionPage/questionPage";
import { IQuestion } from "../formBuilder/interfaces/interfaces";
import { RotatingLines } from "react-loader-spinner";
import {
  clearUserAnswersAC,
  setCurrentFormPageAC,
  setQuestionsAC,
} from "src/store/actions";
import { getFormApi, getPublishedFormApi } from "src/_utils/api/api";
import { Helmet } from "react-helmet";
import HowShallWeContactYou from "./howShallWeContactYou/howShallWeContactYou";
import { useParams } from "react-router-dom";
import PublishFormModal from "./publishFormModal/publishFormModal";

const FormView = () => {
  const questionsList = useSelector((state: any) => state.questionsList);
  const currentIndex = useSelector((state: any) => state.currentFormPage);
  const currentConditionalQuestionsIDs = useSelector(
    (state: any) => state.currentConditionalQuestionsIDs
  );
  const dispatch = useDispatch();

  const { id } = useParams();
  const subdomain = window.location.hostname.split(".")[0];

  const [isLoading, setIsLoading] = useState(true);
  const [endingPageText, setEndingPageText] = useState("Thank you!");
  const [isShowEndingPage, setIsShowEndingPage] = useState(false);
  const [answerIndex, setAnswerIndex] = useState(0);
  const [currentFormId, setCurrentFormId] = useState("");
  const [isFormPublished, setIsFormPublished] = useState(false);
  const [isShowPublishModal, setIsShowPublishModal] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isMaxSubmissionsReached, setIsMaxSubmissionsReached] = useState(false);
  const [businessName, setBusinessName] = useState("[your business name]");
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState("");
  const [facebookPixelId, setFacebookPixelId] = useState("");
  const [googleAdsId, setgoogleAdsId] = useState("");
  const [conversionLabel, setConversionLabel] = useState("");

  useEffect(() => {
    setIsLoading(true);

    if (id) {
      getFormApi(id)
        .then((res) => {
          dispatch(setQuestionsAC(res.data.data.formData.formsData));
          setEndingPageText(
            res.data.data.formData.formsData.endingPageText ?? "Thank you!"
          );
          setCurrentFormId(res.data.data.form.id);
          setIsFormPublished(res.data.data.form.isPublished);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else if (subdomain) {
      getPublishedFormApi(subdomain).then((res) => {
        setCurrentFormId(res.data.data.id);
        dispatch(setQuestionsAC(res.data.data.formsData));
        setIsMaxSubmissionsReached(res.data.data.maxSubmissionReached);
        setEndingPageText(
          res.data.data.formsData?.endingPageText ?? "Thank you!"
        );
        setBusinessName(res.data.data.businessData.businessName);
        setPrivacyPolicyLink(res.data.data.businessData.privacyPolicyLink);
        setFacebookPixelId(res.data.data?.facebookPixelId);
        setgoogleAdsId(res.data.data.googleAdsData?.conversionId);
        setConversionLabel(res.data.data.googleAdsData?.conversionLabel);
        setIsLoading(false);
      });
    }

    return () => {
      dispatch(setCurrentFormPageAC(0));
      dispatch(clearUserAnswersAC());
    };
  }, []);

  const isLastPage =
    currentIndex === questionsList?.questions?.length ||
    currentIndex >
      questionsList.questions?.filter(
        (el: IQuestion) => !currentConditionalQuestionsIDs?.includes(el.id)
      ).length;

  return (
    <>
      <div className={styles.outerWrapper}>
        {isMaxSubmissionsReached && (
          <div className={styles.maxSubmissionsReachedErrWrap}>
            <p>Max submissions reached</p>
          </div>
        )}
        <Helmet>
          <title>LeadQuiz | Form</title>
        </Helmet>
        {!isFormPublished && !isLoading && id && (
          <div className={styles.publshMessage}>
            <p>
              You are currently in viewing mode. Publish the form so others can
              respond to it.
            </p>
            <button onClick={() => setIsShowPublishModal(true)}>Publish</button>
          </div>
        )}
        {!isMaxSubmissionsReached && (
          <img
            className={styles.companyLogo}
            src={questionsList?.logo}
            alt="company logo"
          />
        )}
        {!isMaxSubmissionsReached &&
          (!isLoading ? (
            <div className={styles.wrapper}>
              {questionsList.questions.map((el: IQuestion, index: number) => (
                <QuestionPage
                  key={index}
                  index={index}
                  element={el}
                  isVisible={index === currentIndex}
                  answerIndex={answerIndex}
                  selectedAnswer={selectedAnswer}
                  setSelectedAnswer={setSelectedAnswer}
                  facebookPixelId={facebookPixelId}
                  googleAdsId={googleAdsId}
                  conversionLabel={conversionLabel}
                  isShowEndingPage={isShowEndingPage}
                />
              ))}
              {isLastPage && !isShowEndingPage && <HowShallWeContactYou />}
              {isShowEndingPage && <h1>{endingPageText}</h1>}
              {!isShowEndingPage && (
                <FormViewFooter
                  content={questionsList.questions.filter(
                    (el: IQuestion) =>
                      !currentConditionalQuestionsIDs?.includes(el.id)
                  )}
                  isLastPage={isLastPage}
                  setIsShowEndingPage={setIsShowEndingPage}
                  setAnswerIndex={setAnswerIndex}
                  currentFormId={currentFormId}
                  selectedAnswer={selectedAnswer}
                  setSelectedAnswer={setSelectedAnswer}
                  businessName={businessName}
                  privacyPolicyLink={privacyPolicyLink}
                />
              )}
            </div>
          ) : (
            <RotatingLines
              visible={true}
              width="30"
              strokeWidth="5"
              animationDuration="0.75"
              strokeColor="black"
            />
          ))}
      </div>
      <PublishFormModal
        isShowModal={isShowPublishModal}
        setIsShowModal={setIsShowPublishModal}
        formId={id}
      />
    </>
  );
};

export default FormView;
